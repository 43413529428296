export const homeProductData = [
   {
      "id": 1,
      "productname": "Vinyl Ester Resins",
      "productdes": "Mechemco has been manufacturing Vinyl Ester Resins which are used in the process industry for more than 35 years. These Vinyl Ester Resins have performed well, creating case histories for process equipment in service for more than 30+ years, thus establishing as one of the finest performing brand.",
      "productimg": "VinylEster.jpg"
     },
 {
  "id": 52520,
  "productname": "Gelcoats and Topcoats",
  "productdes": "Gelcoats play a vital role in ensuring the aesthetics and performance of FRP Components. Mechemco’s Gelcoats are designed to provide long life to FRP Composites parts. These Gelcoats are available in different Chemistries and suitable for Processes like Hand Lay-up by Brush / Roller as well as Spray-up. Depending on the performance demand for end products, one can select a suitable grade from a wide range of product portfolio.",
  "productimg": "Gelcoats.jpg"
 },
 {
  "id": 96852,
  "productname": "Solid Surface Resins",
  "productdes": "Mechemco has been providing specialized casting resins suited for the Solid Surface Industry for more than 30 years. Solid Surface Sheets made from these resins are easy to sand and polish, exhibit good Heat Resistance, UV Resistance and Moisture Resistance thus greatly enhancing the life of finished products. These resins are available for Continuous Casting as well as for Batch Mixing process.",
  "productimg": "solidSurface.jpg"
 },
 
 {
  "id": 4,
  "productname": "Compression Molding Resins",
  "productdes": "Mechemco produces  Resins for Compression Molding which are an ideal choice for SMC / BMC / DMC molding as they provide the high quality, reliability and consistent performance required for this process and finished products.",
  "productimg": "Compression.jpg"
 },
 {
  "id": 5,
  "productname": "Infusion Molding Resins",
  "productdes": "Mechemco produces a wide variety of resins having low viscosities suitable for the Infusion Process, low to very high gel times while having quick cure times and controlled exotherms giving flexibility to fabricators to  produce parts of varying sizes.",
  "productimg": "Infusion.jpg"
 },
 {
  "id": 6,
  "productname": "LSE (Low Styrene Emission) Resins",
  "productdes": "Mechemco has products for bonding FRP to FRP, FRP to wood & FRP to metal. These products are widely used in the automotive, marine & wind energy industries. Our products also include putties for moulding & tooling applicationsMechemco produces specially formulated Resins having Low Styrene Emission -  providing a reduction of upto 90% in styrene emissions in open moulding processes, thus helping to create an environmentally friendly working environment in the shopfloor.",
  "productimg": "LowStyrene.jpg"
 },
 {
  "id": 7,
  "productname": "Open Mould Resins",
  "productdes": "Mechemco produces  more than 40 different resins & gelcoats for Hand Layup and Spray Up processes, customized to meet the demand for Open Mould process which remains one of the most used in the  Composite Industry.",
  "productimg": "Open-Mould.jpg"
 },
 {
  "id": 8,
  "productname": "RTM / VARTM Resins",
  "productdes": "Mechemco offers Resins in a wide variety of chemistries including fire retardant chemistries to meet the ever-growing demand for RTM, VARTM & LRTM processes.",
  "productimg": "RTM.jpg"
 },
 {
  "id": 9,
  "productname": "Pultrusion Resins",
  "productdes": "Pultrusion process is one of the fastest growing sectors of composites industry. Mechemco has a portfolio of resins covering wide variety of chemistries including fire retardant options meeting most FR standards. Profiles & Sections made from Mechemco’s  Resins have excellent mechanical properties, high quality finish and a long life creating case histories of more than 20+ years in service. ",
  "productimg": "PultrusionResins.jpg"
 },
 {
  "id": 10,
  "productname": "Adhesives & Putties",
  "productdes": "Mechemco has products for bonding FRP to FRP, FRP to wood & FRP to metal. These products are widely used in the automotive, marine & wind energy industries. Our products also include putties for moulding & tooling applications.",
  "productimg": "Adhesives.jpg"
 },
 {
  "id": 11,
  "productname": "Filament Winding Resins",
  "productdes": "Mechemco manufactures  several chemistries of Resins for both Helical as well as continuous Filament Winding Processes. Depending on the need, one can choose suitable grades to manufacture chemical process piping or suitable for infrastructure.",
  "productimg": "Filament.jpg"
 },
 {
  "id": 12,
  "productname": "Flame Retardant Resins & Gelcoats",
  "productdes": "Mechemco Produces specially formulated Flame Retardant Resins suitable for applications like Electrical, Automotive, Railways, Architectural as well as Building and Infrastructure, where Fire Safety is of utmost importance. These Resins are designed to meet Fire Testing Standards of International (ASTM, UL, BS, DIN, EN, NF, ISO etc.) as well as National. Both, Halogenated and Halogen Free types are available while meeting the mechanical performance requirements.",
  "productimg": "flame.jpg"
 },
 {
    "id": 13,
    "productname": "Tooling Gelcoats and Tooling Resins",
    "productdes": "High Performance Tooling Gelcoat and Tooling Resins manufactured using different chemistries by Mechemco to meet the long-term durability of Tooling and Tool Surface for Open Moulds, Match-Die Moulds, RTM, LRTM Moulds have been known to perform well in the field for past 20+ years. One can choose from available grades of material to meet the needs based on the use of type of Tool / Mould.",
    "productimg": "Tooling.jpg"
   },
   {
    "id": 14,
    "productname": "Coating Resins",
    "productdes": "Mechemco has developed formulated Resins for Wood Coating application meeting the demand for aesthetics combined with high performance. These products are suitable for Furniture and Decorative applications and largely applied by Spray Application.",
    "productimg": "CoatingResins.jpg"
   },
   {
    "id": 15,
    "productname": "Continuous Lamination Resin",
    "productdes": "Mechemco has developed a range of Resin systems suitable for continuous lamination process to manufacture continuous sheets with Flat or different types of Profiles meeting the performance requirements related to FRP applications in various end uses, viz., Roofing Structures, Truck Body Panels as well as Partition Panels etc.",
    "productimg": "Continuous.jpg"
   },
   {
    "id": 16,
    "productname": "Casting Resins",
    "productdes": "Mechemco has Casting Resins which are suitable for Cultured Marble, Engineered Stone, Polymer Concrete where fillers of different kinds are mixed with the resins and moulded to achieve the desired aesthetics and performance properties. Mechemco also specializes in Resins for Clear Casting suitable for statues and decorative applications.",
    "productimg": "CastingResins.jpg"
   },
   {
      "id": 17,
      "productname": "Additives and Miscellaneous Products",
      "productdes": "Numerous processing aids, viz., Inhibitor, Promoter and Accelerator solutions formulated to customized need for curing system, Shrink Control and Low Profile Additives as well as Additive for Topcoat are available to enhance the shop-floor productivity and finished product quality.",
      "productimg": "additives.jpg"
     }
];