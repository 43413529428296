
//page header images
export const ABOUT_HEAD_IMG = "about-head.png";
export const PRODUCTS_HEAD_IMG = "about-head.png";
export const CAREER_HEAD_IMG = "about-head.png";
export const CONTACT_HEAD_IMG = "about-head.png";
export const PDF_URL = "https://mechemco.tecogis.com";
export const PRODUCT_URL = "https://mechemcoapi.tecogis.com/api/product";
export const CATEGORY_URL = "https://mechemcoapi.tecogis.com/api/product/getCategory";
export const CONTACT_URL = "https://mechemcoapi.tecogis.com/api/contact/saveContact";
export const LOGIN_URL = "https://mechemcoapi.tecogis.com/api/user/login";
export const PRODUCT_DTL_URL = "https://mechemcoapi.tecogis.com/api/product_dtl/";
export const REGISTER_URL = "https://mechemcoapi.tecogis.com/api/user/register";
// export const REGISTER_URL = "http://localhost:7000/api/user/register";
export const SEND_EMAIL = "https://mechemcoapi.tecogis.com/api/sendemail/send-email";
// export const SEND_EMAIL = "http://localhost:7000/api/sendemail/send-email";
export const REPORT_URL = "https://mechemcoapi.tecogis.com/api/user/report";
// export const REPORT_URL = "http://localhost:7000/api/user/report";