import React from 'react';
import ReactDOM from 'react-dom/client';

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import './assets/css/custom.css';
import './assets/css/utility.min.css';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, Outlet, RouterProvider} from "react-router-dom";

import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Home from "./pages/home/Home";
import About from "./pages/about/About";
import Products from './pages/products/Products';
import Career from './pages/career/Career';
import Contact from './pages/contact/Contact';
import Downloads from './pages/downloads/Downloads';
import Report from './pages/report/Report';

function AppLayout() {
  return (
      <div className="main-wrapper">
          <Header />
          <Outlet />
          <Footer />
      </div>
  );
}

const appRouter = createBrowserRouter([
  {
      path: "/",
      element: <AppLayout/>,
      children: [
          {
              path: "/",
              element: <Home />
          },
          {
            path: "/about",
            element: <About />
          },
          {
            path: "/products",
            element: <Products />
          },
          {
            path: "/career",
            element: <Career />
          },
          {
            path: "/contact",
            element: <Contact />
          },
          {
            path: "/downloads",
            element: <Downloads/>
          },
          {
            path: "/report",
            element: <Report/>
          }
      ],
      //errorElement: <Error/>
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={appRouter}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
