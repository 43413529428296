import {homeProductData} from '../../utils/homeProductData';
import { useNavigate } from 'react-router-dom';

const HomeProduct = () => {
    const navigate = useNavigate();
    const handleRedirect = (name) => {
        console.log(encodeURIComponent(name))
        navigate(`/products?title=${encodeURIComponent(name)}`);
      };

  return (
    <section className="home-our-products">
        <div className="hop-head">
            <div className="container">
                <div className="row">
                    <div className="col-xl-7 col-lg-7 col-md-6 col-sm-6 col-6">
                        <h2 className="section-head">Our Products</h2>
                    </div>
                    <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6 col-6 justify-content-end">
                        <a href="products" className="btn btn-theme">All products</a>
                    </div>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="row">
                <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12 d-flex">
                    <div className="card hp-card w-100">
                        <div className="card-body">
                        <ul className="nav nav-tabs hptab-head" id="myTab" role="tablist">
                            {
                                homeProductData.map((tabheadlist, index) => (
                                    <li className="nav-item">
                                        <button className={`nav-link ${index === 0 ? 'active' : ''}`} id={"hptab" + tabheadlist.id} data-bs-toggle="tab" data-bs-target={"#hptabpane" + tabheadlist.id} type="button" role="tab" aria-controls={"#hptabpane" + tabheadlist.id} aria-selected="true">{tabheadlist.productname}</button>
                                    </li>
                                ))       
                            }
                        </ul>
                        </div>
                    </div>
                </div>
                <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                <div className="card hp-card hp-img-card">
                        <div className="card-body">
                        <div className="tab-content hptab-content" id="hproductContent">
                            {
                                homeProductData.map((producttabcontentlist, index) =>(
                                    <div className={`tab-pane fade ${index === 0 ? 'show active': ''} `} id={"hptabpane" + producttabcontentlist.id}  role="tabpanel" aria-labelledby={"hptab" + producttabcontentlist.id} tabindex="0">
                                        <img src={require('../../assets/images/product-images/' + producttabcontentlist.productimg)} alt="" className="img-fluid" />
                                        <h3>{producttabcontentlist.productname}</h3>
                                        <p>{producttabcontentlist.productdes} <button className='knowmorebtn'  onClick={() => handleRedirect(producttabcontentlist.productname)}>Know more</button>
                                        </p>
                                        
                                    </div>
                                ))
                            }    
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
}


export default HomeProduct;
