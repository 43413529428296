import React, { useEffect, useState } from "react";
import axios from "axios";
import * as XLSX from "xlsx";  // Import xlsx library
import { REPORT_URL } from "../../utils/constants";


const Report = () => {
  const [reportData, setReportData] = useState([]);

  const fetchData = async () => {
    try {
      const report_data = await axios.get(REPORT_URL);
      const report = report_data.data;
      setReportData(report);
    } catch (error) {
      console.error("Error in fetching data", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const downloadExcel = () => {
//     const fileNameWithExtension = pdfPath.split('/').pop();
// console.log(fileNameWithExtension);
    const selectedColumns = reportData.map(({ firstName, lastName, email,mobile_no, productName, pdfLink,createdAt}) => ({
      'First Name': firstName,
      'Last Name': lastName,
      'Email': email,
      'Mobile No': mobile_no,
      'Product Name': productName,
      'PDF Link': pdfLink ? pdfLink.split('/').pop() : 'N/A',
      'Date': createdAt,
    }));
    const worksheet = XLSX.utils.json_to_sheet(selectedColumns);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "ReportData");
    XLSX.writeFile(workbook, "ReportData.xlsx");
  };


  return (
    <>
      <section className="page-head-section pb-0">
        <div className="container mt-40">
          <div className="row">
            <div className="col-12 text-center mb-40"><h2>TDS Download Status</h2></div>
            <div className="col-12">
              <div className='table-responsive'>
                <table className='table table-bordered table-striped'>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email Id</th>
                      <th>Mobile No.</th>
                      <th>Product Name</th>
                      <th>TDS/PDF Name</th>
                      <th>Date & Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportData.map((report) => {
                      const formattedDate = new Date(report.createdAt).toLocaleString("en-IN", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                        hour12: false, // Use 24-hour format
                      });
                      return (
                        <tr key={report._id}>
                          <td>{report.firstName} {report.lastName}</td>
                          <td>{report.email}</td>
                          <td>{report.mobile_no}</td>
                          <td>{report.productName}</td>
                          <td>{report.pdfLink ? report.pdfLink.split('/').pop() : 'N/A'}</td>
                          <td>{formattedDate}</td>
                          {/* <td>{report.pdfLink ? ( <span> View</span> ) : ( "Not Available" )}</td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-12 text-center mb-40"><button onClick={downloadExcel}>Download Excel</button></div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Report